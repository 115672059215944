<template>
  <div justify="center">
    <v-btn
      color="secondary"
      outlined
      elevation="0"
      @click.stop="openForm"
    >
      <v-icon
        size="17"
        class="me-1"
      >
        {{ icons.mdiDotsHorizontal }}
      </v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="900px"
    >
      <div>
        <v-card>
          <v-card-title>仕入先検索一覧</v-card-title>
          <v-divider></v-divider>
          <v-row class="px-2 ma-0">
            <v-col
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="sup_code"
                :items="supCodes"
                outlined
                dense
                clearable
                placeholder="仕入先コード"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-autocomplete
                v-model="sup_name"
                :items="supNames"
                outlined
                dense
                clearable
                placeholder="仕入先名"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <v-card-text class="d-flex align-center flex-wrap pb-0">
            <v-text-field
              v-model="search"
              placeholder="Search"
              outlined
              hide-details
              dense
              class="user-search mb-4"
            >
            </v-text-field>
          </v-card-text>
          <v-data-table
            v-model="selectedList"
            show-select
            single-select
            :headers="headers"
            :items="tableList"
            add-supplier-list="getaddSupplierList"
            sort-by
            class="elevation-0"
            :search="search"
          >
            <template v-slot:[`item.status`]="{ item }">
              <div>
                <v-chip
                  outlined
                  :color="getColor(item.status)"
                >
                  {{ item.status }}
                </v-chip>
              </div>
            </template>
            <template v-slot:[`item.img`]="{ item }">
              <div class="p-2">
                <v-img
                  :src="item.img ? item.img : require('@/assets/images/noimage.jpg')"
                  max-height="50"
                  max-width="50"
                  class="me-3"
                ></v-img>
              </div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-row v-if="!item.processed">
                <v-col
                  style="padding-right: 10px"
                  cols="6"
                >
                  <v-icon
                    class="mr-2"
                    small
                    @click="editItem(item)"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                </v-col>
                <v-col
                  style="padding-left: 10px"
                  cols="6"
                >
                  <delete-form
                    :icon="icons.mdiDelete"
                    :crushed-id="item.crushed_id"
                  ></delete-form>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="mr-2"
              @click="goBack"
            >
              <span>OK</span>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              @click="dialog = false"
            >
              <span>キャンセル</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiDotsHorizontal,
} from '@mdi/js'

export default {
  components: {
  },
  data: () => ({
    dialog: false,
    sup_code: '',
    sup_name: '',
    supCodes: [],
    supNames: [],
    search: '',
    arrBfo: [],
    selectedList: [],
    addSupplierList: [],
    icons: {
      mdiDotsHorizontal,
    },
    headers: [
      {
        text: '仕入先コード',
        value: 'sup_code',
        align: 'left',
        width: '30%',
        sortable: false,
        fixed: true,
      },
      {
        text: '仕入先名',
        align: 'left',
        sortable: true,
        value: 'sup_name',
        width: '45%',
        fixed: true,
      },
      {
        text: '担当者',
        value: 'sup_name_abbr',
        width: '25%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
    ],
    tableList: [],
  }),
  computed: {
    ...mapState('supplierStore', ['supplierList']),
  },
  watch: {
    selectedList(after) {
      this.addSupplierList = after
    },
    sup_name(newValue) {
      console.log(newValue)
      this.doFilter()
    },
    sup_code(newValue) {
      console.log('sup_code', newValue)
      this.doFilter()
    },
  },
  created() {
    this.loadQuery()
    this.loadData()

    this.arrBfo = this.supplierList
  },

  methods: {
    ...mapMutations('app', ['setOverlayStatus']),
    ...mapActions('supplierStore', ['loadSupplierList']),
    openForm() {
      this.dialog = true
    },
    goBack() {
      this.dialog = false
      this.$emit('add_supplierlist', this.addSupplierList)
      this.showSelect = false
    },
    loadData() {
      this.setOverlayStatus(true)
      this.loadSupplierList(this.$route.query).then(() => {
        this.supCodes = []
        this.supNames = []
        this.tableList = [...this.supplierList]
        this.supplierList.forEach(i => {
          if (i.sup_code) {
            this.supCodes.push(i.sup_code)
          }
          if (i.sup_name) {
            this.supNames.push(i.sup_name)
          }
        })
        this.setOverlayStatus(false)
      })
    },
    doFilter() {
      let filterResult = this.arrBfo

      if (this.sup_code !== null && this.sup_code !== '') {
        filterResult = filterResult.filter(element => element.sup_code === this.sup_code)
      }

      if (this.sup_name !== null && this.sup_name !== '') {
        filterResult = filterResult.filter(element => element.sup_name === this.sup_name)
      }
      this.tableList = filterResult
    },
    loadQuery() {
      this.sup_name = this.$route.query.sup_name ?? ''
      this.sup_code = this.$route.query.sup_code ?? ''
    },
  },
}
</script>
<style>
.table-filed {
  white-space: normal;
}
.text-format {
  text-align: right !important;
}
.v-input__prepend-outer {
  margin: auto !important;
}
.text-field-dense-append-prepend-margin {
  margin: auto;
}
.v-input__append-inner {
  margin: auto !important;
}
/* .v-data-table tr:nth-child(odd) td {
  background-color: #F0FFFF;
} */
</style>
