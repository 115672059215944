<template>
  <div>
    <v-card>
      <v-card-title>仕入実績（新規）</v-card-title>
      <v-divider></v-divider>
      <v-row align="center" class="px-2 ma-0 mt-2">
        <v-col
          cols="12"
          md="2"
        >
          <label>仕入日</label><span style="color:red">*</span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            v-model="dateStartMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="$v.purchasedForm.purc_date.$model"
                :error-messages="purcDateErrors"
                placeholder="仕入日"
                dense
                hide-details="auto"
                outlined
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="purchasedForm.purc_date"
              :allowed-dates="previousDays"
              @input="dateStartMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 my-0">
        <v-col
          cols="12"
          md="2"
        >
          <label>仕入先コード</label><span style="color:red">*</span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="$v.purchasedForm.sup_code.$model"
            :error-messages="purcSupCodeErrors"
            readonly
            outlined
            dense
            placeholder="仕入先コード"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <SupplierID
            @add_supplierlist="getaddSupplierList"
          ></SupplierID>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 my-0">
        <v-col
          cols="12"
          md="2"
        >
          <label>仕入先名</label>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            readonly
            outlined
            dense
            placeholder="仕入先名"
            :value="supName"
            hide-details="auto"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 my-0">
        <v-col
          cols="12"
          md="2"
        >
          <label>商品コード</label><span style="color:red">*</span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="$v.purchasedForm.item_code.$model"
            :error-messages="purcItemCodeErrors"
            readonly
            outlined
            dense
            placeholder="商品コードor自社コード"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="1"
        >
          <ProList
            :single-select="true"
            :show-btn="false"
            @add_mainAllList="getaddMainAllList"
          ></ProList>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 my-0">
        <v-col
          cols="12"
          md="2"
        >
          <span>商品名</span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            dense
            placeholder="商品名"
            outlined
            readonly
            hide-details
            :value="itemName"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 my-0">
        <v-col
          cols="12"
          md="2"
        >
          <span>メーカー品名</span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            outlined
            dense
            readonly
            placeholder="メーカー品名"
            hide-details="auto"
            :value="makerName"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 my-0">
        <v-col
          cols="12"
          md="2"
        >
          <label>賞味期限</label>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            v-model="dateEndMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="purchasedForm.purc_exp_date"
                placeholder="賞味期限"
                dense
                hide-details="auto"
                outlined
                readonly
                v-bind="attrs"
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="purchasedForm.purc_exp_date"
              @input="dateEndMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 my-0">
        <v-col
          cols="12"
          md="2"
        >
          <label>仕入単価(税込)</label><span style="color:red">*</span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <NumberInput
            v-model="purchasedForm.purc_tip"
            :error-messages="purcTipErrors"
            :outlined="true"
          ></NumberInput>
        </v-col>
      </v-row>
      <v-row align="center" class="px-2 ma-0 my-0">
        <v-col
          cols="12"
          md="2"
        >
          <label>仕入数量</label><span style="color:red">*</span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="$v.purchasedForm.purc_qty.$model"
            :error-messages="purcQtyErrors"
            placeholder="仕入数量"
            dense
            reverse
            hide-details="auto"
            outlined
          ></v-text-field>
        </v-col>
        <p class="pb-0 mb-0" style="color:#fa7a00;font-size: small;">
          {{ refValue1.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}
        </p>
      </v-row>
      <v-row align="center" class="px-2 ma-0 my-0 mb-3">
        <v-col
          cols="12"
          md="2"
        >
          <label>仕入総額(税込)</label><span style="color:red">*</span>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <NumberInput
            v-model="purchasedForm.purc_amount"
            :error-messages="purcAmountErrors"
            :outlined="true"
          ></NumberInput>
        </v-col>
        <p v-if="gapValueOn" class="pb-0 mb-0" style="color:#FF0000;font-size: small;">
          {{ gapValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}
        </p>
      </v-row>
      <v-divider></v-divider>
      <v-card-actions align="center" class="d-flex justify-center">
        <v-btn
          color="primary"
          class="mr-2"
          elevation="0"
          :loading="submitStatus"
          @click="submit(purchasedForm)"
        >
          <span style="color:white">保存</span>
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          elevation="0"
          @click="cancelClick"
        >
          <span>キャンセル</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import {
  mdiDotsHorizontal,
} from '@mdi/js'
import {
  required,
  maxLength,
  numeric,
  minValue,
} from 'vuelidate/lib/validators'
import {
  mapActions,
} from 'vuex'
import SupplierID from '../components/SupplierID.vue'
import ProList from '@/components/ProList.vue'
import NumberInput from '@/components/Input/NumberInput.vue'

export default {
  components: {
    SupplierID,
    ProList,
    NumberInput,
  },
  data: () => ({
    dateEndMenu: '',
    dateStartMenu: '',
    itemName: '',
    makerName: '',
    supName: '',
    icons: {
      mdiDotsHorizontal,
    },
    sedValue: [],
    sedNumber: [],
    submitStatus: false,
    purchasedForm: {
      item_code: '',
      sup_code: '',
      purc_date: '',
      purc_exp_date: '',
      purc_qty: '',
      purc_tip: '0',
      purc_amount: '0',
    },
    refValue1: '0',
    gapValue: '0',
    gapValueOn: false,
    calculateValue: '',
    changeFlag: false,
  }),

  validations: {
    purchasedForm: {
      item_code: {
        required,
      },
      sup_code: {
        required,
        maxLength: maxLength(10),
      },
      purc_date: {
        required,
      },
      purc_qty: {
        required,
        numeric,
        minValue: minValue(1),
      },
      purc_tip: {
        required,
        numeric,
        minValue: minValue(1),
      },
      purc_amount: {
        required,
        numeric,
        minValue: minValue(1),
      },
    },
  },

  computed: {
    purcItemCodeErrors() {
      const errors = []
      if (!this.$v.purchasedForm.item_code.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.purchasedForm.item_code.required && errors.push('必須項目')

      return errors
    },
    purcSupCodeErrors() {
      const errors = []
      if (!this.$v.purchasedForm.sup_code.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.purchasedForm.sup_code.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.purchasedForm.sup_code.maxLength && errors.push('10文字以内にしてください。')

      return errors
    },
    purcDateErrors() {
      const errors = []
      if (!this.$v.purchasedForm.purc_date.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.purchasedForm.purc_date.required && errors.push('必須項目')

      return errors
    },
    purcQtyErrors() {
      const errors = []
      if (!this.$v.purchasedForm.purc_qty.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.purchasedForm.purc_qty.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.purchasedForm.purc_qty.numeric && errors.push('必須数字')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.purchasedForm.purc_qty.minValue && errors.push('仕入数量は0以外にしてください')

      return errors
    },
    purcTipErrors() {
      const errors = []
      if (!this.$v.purchasedForm.purc_tip.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.purchasedForm.purc_tip.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.purchasedForm.purc_tip.numeric && errors.push('必須数字')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.purchasedForm.purc_tip.minValue && errors.push('0以上にしてください')

      return errors
    },
    purcAmountErrors() {
      const errors = []
      if (!this.$v.purchasedForm.purc_amount.$dirty) return errors

      // eslint-disable-next-line no-unused-expressions
      !this.$v.purchasedForm.purc_amount.required && errors.push('必須項目')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.purchasedForm.purc_amount.numeric && errors.push('必須数字')

      // eslint-disable-next-line no-unused-expressions
      !this.$v.purchasedForm.purc_amount.minValue && errors.push('０以上にしてください')

      return errors
    },
  },

  watch: {
    purchasedForm: {
      handler() {
        this.changeFlag = true
      },
      deep: true,
    },
    'purchasedForm.purc_date': {
      handler(newValue) {
        this.sedValue = new Date(Date.parse(newValue))
        this.sedNumber = this.sedValue.setMonth(this.sedValue.getMonth() + 12)
        this.purchasedForm.purc_exp_date = new Date(this.sedNumber).toISOString().substring(0, 10)
      },
    },
    'purchasedForm.purc_qty': {
      handler() {
        this.refValue1 = Math.round(this.purchasedForm.purc_qty * this.purchasedForm.purc_tip)
        this.purchasedForm.purc_amount = Math.round(this.purchasedForm.purc_qty * this.purchasedForm.purc_tip)
      },
    },
    'purchasedForm.purc_tip': {
      handler() {
        this.refValue1 = Math.round(this.purchasedForm.purc_qty * this.purchasedForm.purc_tip)
        this.purchasedForm.purc_amount = Math.round(this.purchasedForm.purc_qty * this.purchasedForm.purc_tip)
      },
    },
    'purchasedForm.purc_amount': {
      handler() {
        this.gapValue = this.purchasedForm.purc_amount - this.refValue1
        if (this.gapValue !== 0) {
          this.gapValueOn = true
        } else {
          this.gapValueOn = false
        }
      },
    },
  },
  methods: {
    ...mapActions('purchasedStore', ['createPurchased']),

    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },

    previousDays(val) {
      return Date.parse(val) <= Date.now()
    },
    getaddSupplierList(addCusList) {
      console.log('getaddSupplierList', addCusList)
      this.supName = addCusList[0].sup_name
      this.purchasedForm.sup_code = addCusList[0].sup_code
    },
    getaddMainAllList(item) {
      this.purchasedForm.item_code = item[0].item_code
      this.itemName = item[0].item_name
      this.makerName = item[0].maker_name
    },
    jumpToListPage() {
      this.$router.push({
        path: '/purchase-list',
        query: this.$route.params.query ?? {
        },
      })
    },

    submitTo(purchasedForm) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.createPurchased(purchasedForm)
          .then(() => {
            this.jumpToListPage()
          })
          .catch(error => {
            console.log('apierror', error)
          })
          .finally(() => {
            this.submitStatus = false
          })
      } else {
        console.log('error submit!!')
      }
    },

    submit(purchasedForm) {
      console.log('purchasedForm:', purchasedForm)

      if (this.purchasedForm.purc_amount >= Number(this.refValue1) + 10 || this.purchasedForm.purc_amount <= Number(this.refValue1) - 10) {
        this.calculateValue = this.purchasedForm.purc_amount - this.refValue1
        if (window.confirm(`仕入総額の計算差額は${Math.abs(this.calculateValue)}円です，よろしいですか?`)) {
          this.submitTo(purchasedForm)
        } else {
          console.log(purchasedForm)
        }
      } else {
        this.submitTo(purchasedForm)
      }
    },
  },
}
</script>
<style>
.ql-editor{
  min-height: 120px;
}
</style>
