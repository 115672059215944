<template>
  <div justify="center">
    <v-icon
      v-if="showBtn"
      size="17"
      class="me-1"
      @click.stop="openForm"
    >
      {{ icons.mdiFormatListBulleted }}
    </v-icon>
    <v-btn
      v-else
      color="secondary"
      outlined
      elevation="0"
      @click.stop="openForm"
    >
      <v-icon
        size="17"
        class="me-1"
      >
        {{ icons.mdiDotsHorizontal }}
      </v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="900px"
    >
      <div>
        <v-card>
          <overlays
            style="z-index:10 !important"
            :is-absolute="true"
            :is-show="absoluteOverlayStatus"
          ></overlays>
          <v-row class="px-2 ma-0">
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="search"
                placeholder="Search"
                outlined
                hide-details
                dense
                class="user-search"
              >
              </v-text-field>
            </v-col>
            <v-col
              v-if="selectedShow"
              cols="12"
              md="12"
            >
              <v-sheet
                width="100%"
                min-height="1"
                class="d-flex flex-wrap"
              >
                <div
                  v-for="(item, id) in chipList"
                  :key="id"
                  class="mb-1 mr-2"
                >
                  <v-chip
                    close
                    @click:close="remove(item)"
                  >
                    <v-icon
                      size="16"
                      class="mr-1"
                    >
                      {{ icons.mdiMinusBoxOutline }}
                    </v-icon>
                    <span
                      style="font-size:1px; color:black"
                    >
                      <v-avatar left>
                        <v-img :src="item.image ? item.image : require('@/assets/images/noimage.jpg')"></v-img>
                      </v-avatar>
                      {{ item.item_name }}
                    </span>
                  </v-chip>
                </div>
              </v-sheet>
            </v-col>
          </v-row>
          <v-data-table
            v-model="selectedList"
            show-select
            :headers="Headers"
            :search="search"
            :items="tableList"
            item-key="id"
            :single-select="singleSelect"
            class="elevation-0"
            style="padding:0px"
            @item-selected="itemSelected($event)"
            @toggle-select-all="toggleSelectALl($event)"
          >
            <template v-slot:[`item.image`]="{ item }">
              <div class="p-2">
                <v-img
                  :src="item.image ? item.image : require('@/assets/images/noimage.jpg')"
                  max-height="50"
                  max-width="40"
                  class="me-3"
                  style="padding:0px"
                ></v-img>
              </div>
            </template>
            <template v-slot:[`item.item_up_value`]="{ item }">
              <label>{{ item.item_up_value ? item.item_up_value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') : "" }}</label>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="me-3"
              @click="submit"
            >
              <span style="color:#fff">OK</span>
            </v-btn>
            <v-btn
              color="secondary"
              outlined
              class="me-3"
              @click="goBack"
            >
              <span>キャンセル</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  mapState, mapActions, mapMutations,
} from 'vuex'
import {
  mdiFormatListBulleted, mdiDotsHorizontal,
} from '@mdi/js'

// import SelectCategoryTree from '@/components/SelectList/SelectCategoryTree.vue'
import Overlays from '@/components/Overlays.vue'

export default {
  components: {
    // SelectCategoryTree,
    Overlays,
  },
  props: ['oldList', 'singleSelect', 'showBtn'],
  data: () => ({
    dialog: false,
    tableList: [],
    search: '',
    lc_code: 1,
    mc_code: '',
    sc_code: '',
    icons: {
      mdiFormatListBulleted,
      mdiDotsHorizontal,
    },
    initialSelectedCodes: [1],
    selectedList: [],
    chipList: [],
    addMainAllList: [],
    selectedShow: false,
    Headers: [
      {
        text: 'イメージ',
        value: 'image',
        align: 'left',
        width: '10%',
        sortable: false,
        fixed: true,
      },
      {
        text: '商品ID',
        value: 'id',
        align: 'left',
        width: '10%',
        sortable: false,
        fixed: true,
      },
      {
        text: '商品コード',
        value: 'item_code',
        width: '20%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '商品名',
        value: 'item_name',
        width: '60%',
        align: 'left',
        sortable: true,
        fixed: true,
      },
      {
        text: '',
        value: '',
        width: '5%',
        align: 'right',
        sortable: false,
        fixed: false,
      },
    ],
  }),
  computed: {
    ...mapState('itemStore', ['itemList']),
    ...mapState('app', ['absoluteOverlayStatus']),
    codeList() {
      let array = []
      if (this.lc_code || this.mc_code || this.sc_code) {
        array = [this.lc_code, this.mc_code, this.sc_code]
      } else {
        array = [1]
      }

      return array
    },
  },
  watch: {
    tableList: {
      handler(newValue) {
        if (newValue.length !== 0 && this.oldList) {
          this.selectedList = this.oldList.map(ol => {
            const item = this.itemList.find(tl => tl.item_code === ol.item_code)
            item.item_qty = ol.item_qty

            return item
          })
          this.chipList = this.oldList.map(ol => {
            const item = this.itemList.find(tl => tl.item_code === ol.item_code)
            item.item_qty = ol.item_qty

            return item
          })
        }
      },
      deep: true,
    },
    chipList(newValue) {
      console.log('newValue', newValue)

      if (newValue.length === 0) {
        this.selectedShow = false
      } else {
        this.selectedShow = true
      }
    },
  },
  destroyed() {
    this.tableList = []
  },
  methods: {
    ...mapMutations('app', ['setAbsoluteOverlayStatus']),
    ...mapActions('itemStore', ['loadItemList']),
    toggleSelectALl(event) {
      if (event.value) {
        this.chipList = event.items
      } else {
        this.chipList = []
      }
    },
    itemSelected(event) {
      if (event.value) {
        this.chipList.push(event.item)
      } else {
        this.remove(event.item)
      }
    },

    loadData() {
      this.setAbsoluteOverlayStatus(true)
      this.loadItemList().then(() => {
        this.tableList = [...this.itemList]

        // get itemList image
        this.tableList.forEach((il, i) => {
          if (il.images) {
            // eslint-disable-next-line prefer-destructuring
            this.tableList[i].image = `${il.imgUrl_prefix}/${il.images.split('"')[1]}`
          } else {
            this.tableList[i].image = ''
          }
        })
        console.log('loadData', this.tableList)
      }).finally(() => {
        this.setAbsoluteOverlayStatus(false)
      })
    },

    openForm() {
      this.dialog = true
      console.log('NewList', this.oldList)
      this.loadData()
    },
    goBack() {
      this.dialog = false
    },
    submit() {
      this.dialog = false
      this.addMainAllList = this.chipList
      this.$emit('add_mainAllList', this.addMainAllList)
      console.log('closeForm', this.addMainAllList)
    },
    remove(item) {
      const index = this.selectedList.findIndex(el => el.item_name === item.item_name)
      if (index >= 0) this.selectedList.splice(index, 1)
      const indexChip = this.chipList.findIndex(el => el.item_name === item.item_name)
      if (indexChip >= 0) this.chipList.splice(indexChip, 1)
    },
  },
}
</script>

<style>
</style>
